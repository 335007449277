<template>
    <b-form-tags
    id="tags-component-select"
    :value="selected"
    size="lg"
    class="mb-2"
    add-on-change
    no-outer-focus
    >
        <template v-slot="{ tags, disabled }">
            <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
            <li v-for="tag in mappedTags" :key="tag.value" class="list-inline-item">
                <b-form-tag
                    @remove="removeTag(tag)"
                    :title="tag.text"
                    :disabled="disabled"
                    variant="info"
                    >
                        {{ tag.text }}
                </b-form-tag>
            </li>
            </ul>
            <b-form-select
                @change="addTag"
                :disabled="disabled || availableOptions.length === 0"
                :options="availableOptions"
                >
                <template #first>
                    <!-- This is required to prevent bugs with Safari -->
                    <option disabled value="">Kies een optie...</option>
                </template>
            </b-form-select>
        </template>
    </b-form-tags>
</template>

<script>
export default({
    props: {
        value: {
            type: [String, Array],
            required: false,
        },
        options: {
            type: [Boolean, Array],
            required: false,
        },
        disabled: {
            type: [Boolean],
            required: false,
        },
    },
    data() {
        return {
            selected: []
        }
    },
    computed: {
        availableOptions() {
            return this.options.filter(option => this.selected.indexOf(option.value) === -1)
        },
        mappedTags() {
            return this.options.filter(option => this.selected.includes(option.value))
        }
    },
    methods: {
        addTag: function(value) {
            this.selected.push(value);
            this.$emit('input', this.selected);
            this.$emit('change');
        },
        removeTag: function(tag) {
            var index = this.selected.indexOf(tag.value);
            if (index !== -1) {
                this.selected.splice(index, 1);
            }
            this.$emit('input', this.selected);
            this.$emit('change');
        }
    },
    watch: {
        value: function() {
            this.selected = (this.value !== null && this.value != '' ? this.value : []);
        }
    },
    created() {
        this.selected = (this.value !== null && this.value != '' ? this.value : []);
    }
})
</script>
